import React from "react";
import RootLayout from "./src/components/RootLayout";

export const wrapRootElement = ({ element, props }) => <RootLayout {...props} element={element} />;

export const onRouteUpdate = ({ location, prevLocation }) => {
  let last20Routes = JSON.parse(sessionStorage.getItem("last20Routes")) || [];

  sessionStorage.setItem("currentLocationObj", JSON.stringify(location));
  sessionStorage.setItem("prevLocationObj", JSON.stringify(prevLocation));

  last20Routes.unshift(location);
  last20Routes = last20Routes.slice(0, 20);

  sessionStorage.setItem("last20Routes", JSON.stringify(last20Routes));

  // Using this ensures that the language prefix set from gatsby-i18next-language will always trigger
  // if (typeof window !== "undefined") {
  //   const currentLanguage = localStorage.getItem("gatsby-i18next-language");
  //   const languagePrefix = `/${currentLanguage}`;
  //   const isDefaultLanguage = currentLanguage === "bg";
  //   const { origin, href } = window.location;

  //   const path = href.replace(origin, "");

  //   console.log(path);

  //   if (!isDefaultLanguage && !path.startsWith(languagePrefix)) {
  //     const newPathname = `${languagePrefix}${path}`;

  //     console.log(window.location);

  //     window.history.replaceState({}, "", newPathname);
  //     window.location.reload();
  //   }
  // }
};
