import React, { useEffect, useState } from "react";
import { setCookie } from "../../utils/functions";

import "@styles/components/GoogleConsentModal.sass";

const textConfig = {
  eng: {
    cookieTitle: "The website uses cookies",
    cookieConsent: "By continuing to use the site, you agree to our use of cookies. ",
    cookiePolicy: "Cookie policy.",
    consent: "I agree"
  },
  bg: {
    cookieTitle: "Уебсайтът използва бисквитки",
    cookieConsent: 'Продължавайки да използваш сайта, се съгласяваш с употребата на "бисквитки" от нас. ',
    cookiePolicy: "Политика за бисквитки.",
    consent: "Съгласен съм"
  }
};

const GoogleConsentModal = () => {
  const [isShown, setIsShown] = useState(true);
  const [isEng, setIsEng] = useState(false);

  const hasEn = str => str.includes("/en/");

  const grantConsent = consentObject => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("consent", "update", consentObject);
    }
  };

  const handleConsentGranted = consentObject => {
    grantConsent(consentObject);
    setCookie("boleron-google-consent", "true", 3650);
    setIsShown(false);
  };

  useEffect(() => {
    /*if (typeof window !== "undefined" && window.gtag) {
      window.gtag("consent", "default", {
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        analytics_storage: "denied",
        wait_for_update: 500
      });
    } */
    setIsShown(true);

    // NOTE: Not the best practice but in order to escape hydration problems I came up with this
    const handleConsentTimeout = setTimeout(() => {
      const consentBox = document.getElementById("google-consent-box");
      if (consentBox) {
        consentBox.classList.add("slide-out");
      }
      setTimeout(() => {
        handleConsentGranted({
          ad_storage: "granted",
          ad_user_data: "granted",
          ad_personalization: "denied",
          analytics_storage: "granted"
        });
      }, 1000);
    }, 15000);

    const onRouteUpdate = event => {
      const { url } = event.destination;
      setIsEng(hasEn(url));
    };

    window?.navigation?.addEventListener?.("navigate", onRouteUpdate);

    return () => {
      clearTimeout(handleConsentTimeout);
      window?.navigation?.removeEventListener?.("navigate", onRouteUpdate);
    };
  }, []);

  return (
    isShown && (
      <div id="google-consent-box" className="google-consent-box">
        <div className="content-text-wrapper">
          <div className="content-text-box">
            <div className="consent-text-box ">
              <p className="consent-title">{textConfig[isEng ? "eng" : "bg"].cookieTitle}</p>

              <p className="consent-msg">
                {textConfig[isEng ? "eng" : "bg"].cookieConsent}
                <a className="a-link" target="_blank" rel="noreferrer" href="https://devdiag558.blob.core.windows.net/documents/BOLERON_COOKIES_20200519.pdf">
                  {textConfig[isEng ? "eng" : "bg"].cookiePolicy}
                </a>
              </p>
            </div>

            <p className="has-text-centered mt-3">
              <button
                className="button is-info is-rounded py-2 px-4 mb-2 theme-button consent-ok-button"
                type="button"
                onClick={() =>
                  handleConsentGranted({
                    ad_storage: "granted",
                    ad_user_data: "granted",
                    ad_personalization: "granted",
                    analytics_storage: "granted"
                  })
                }
              >
                <span className="regular-txt is-uppercase is-size-4"> {textConfig[isEng ? "eng" : "bg"].consent}</span>
              </button>
            </p>
          </div>
        </div>
      </div>
    )
  );
};

export default GoogleConsentModal;
