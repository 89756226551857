import React from "react";
import "@styles/components/loading-element.sass";

const BoleronLoader = props => {
  const { isSmall, spinnerColor, loaderLines = 12, wrapperClasses } = props;

  return (
    <div className={`loader-wrapper is-active ${wrapperClasses || ""}`}>
      <div className={`lds-spinner ${isSmall ? "small" : "default pt-1"} ${spinnerColor ? "spinner-color" : "default-spinner"}`}>
        {[...Array(loaderLines)].map((_, i) => (
          <div key={i} style={spinnerColor ? { "--spinner-color": spinnerColor } : {}} />
        ))}
      </div>
    </div>
  );
};

export default BoleronLoader;
